import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`;

export default class BackgroundImage extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  render() {
    const { className, url, children, ...props } = this.props;
    return (
      <Container className={className} url={url} {...props}>
        {children}
      </Container>
    );
  }
}

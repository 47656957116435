import React from 'react';

import { SiteConfig } from '../config';
import FormModal from './form-modal';
import { formPropTypes } from '../../utils/prop-types';

export default class HlrContactModal extends React.Component {
  static propTypes = {
    form: formPropTypes().isRequired,
  };

  render() {
    return (
      <SiteConfig>
        {({ contactFormUrl }) => (
          <FormModal
            className="h-hlr-contact-modal"
            title="I want more information on the Hone Lab"
            controls={[
              new FormModal.Form.FullName('Name'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.PhoneNumber('PhoneNumber', false),
              new FormModal.Form.Message('Message'),
            ]}
            submitTo={`${contactFormUrl}?subject=Hone Lab`}
            {...this.props}
            {...this.props.form}
          />
        )}
      </SiteConfig>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Nav as NavBase, NavDropdown } from 'react-bootstrap';
import { Link } from 'gatsby';
import { SiteConfig } from '../config';
import { Image } from '../images';
import { ExternalLink } from '../links';

class Solution extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    to: PropTypes.string,
    url: PropTypes.string,
  };

  render() {
    const { type, icon, title, description, to, url } = this.props;
    const className = `h-solution-${type}`;
    const content = (
      <>
        <div className="h-solution-icon">
          <Image src={icon} alt="" />
        </div>
        <div className="h-solution-content">
          <div className="h-solution-title">{title}</div>
          <div className="h-solution-description">{description}</div>
        </div>
      </>
    );

    return to ? (
      <Link className={className} to={to}>
        {content}
      </Link>
    ) : (
      <ExternalLink className={className} url={url}>
        {content}
      </ExternalLink>
    );
  }
}

export default class AgNav extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    testDescription: PropTypes.string.isRequired,
    createDescription: PropTypes.string.isRequired,
    honeLabDescription: PropTypes.string.isRequired,
  };

  render() {
    const {
      className,
      testDescription,
      createDescription,
      honeLabDescription,
    } = this.props;

    const itemClassName = className ? `${className}-item` : null;

    return (
      <SiteConfig>
        {({ testIconUrl, createIconUrl, hlrIconUrl, createPublicUrl }) => (
          <NavBase className={classNames('h-page-nav', className)}>
            <NavDropdown
              className={classNames(
                'h-page-nav-solutions',
                'h-page-nav-item',
                itemClassName
              )}
              title="Solutions"
            >
              <NavDropdown.Item
                as={Solution}
                type="test"
                icon={testIconUrl}
                title="Classic Test"
                description={testDescription}
                to="/hone-test"
              />
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Solution}
                type="create"
                icon={createIconUrl}
                title="Create"
                description={createDescription}
                to={createPublicUrl}
              />
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Solution}
                type="hone-lab"
                icon={hlrIconUrl}
                title="Hone Lab"
                description={honeLabDescription}
                to="/hone-lab"
              />
            </NavDropdown>
            <NavBase.Item
              className={classNames('h-page-nav-item', itemClassName)}
            >
              <Link to="/#partners" data-smooth-scroll>
                Partners
              </Link>
            </NavBase.Item>
            <NavBase.Item
              className={classNames('h-page-nav-item', itemClassName)}
            >
              <Link to="/company">Company</Link>
            </NavBase.Item>
            <NavDropdown
              className={classNames(
                'h-page-nav-testing',
                'h-page-nav-item',
                itemClassName
              )}
              title="Start Testing Now"
            >
              <NavDropdown.Item
                as={Solution}
                type="test"
                icon={testIconUrl}
                title="Classic Test"
                description={testDescription}
                to="/hone-test"
              />
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Solution}
                type="hone-lab"
                icon={hlrIconUrl}
                title="Hone Lab"
                description={honeLabDescription}
                to="/hone-lab"
              />
            </NavDropdown>
          </NavBase>
        )}
      </SiteConfig>
    );
  }
}

const honeAgKey = 'honeag';
const honeCreateKey = 'honecreate';
const honeCarbonKey = 'honecarbon';
const honeLiquidKey = 'honeliquid';
const target = process.env.GATSBY_BUILD_TARGET || honeAgKey;

module.exports = {
  honeAgKey,
  honeCreateKey,
  honeCarbonKey,
  honeLiquidKey,
  getTarget: () => target,
  isTarget: (key) => target === key,
};

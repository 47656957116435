import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import { SiteConfig } from '../config';

export default class Head extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title } = this.props;
    return (
      <SiteConfig>
        {({ faviconUrl }) => (
          <Helmet
            title={title}
            link={[
              { rel: 'icon', type: 'image/x-icon', href: faviconUrl },
              {
                rel: 'shortcut icon',
                type: 'image/x-icon',
                href: faviconUrl,
              },
            ]}
          />
        )}
      </SiteConfig>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';

import { SiteConfig } from '../config';

import Head from './head';
import Header from './header';
import Footer from './footer';

export default class Layout extends React.Component {
  static defaultProps = {
    lang: 'en',
    meta: [],
  };

  static propTypes = {
    lang: PropTypes.string,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    meta: PropTypes.arrayOf(PropTypes.object),
    header: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { lang, className, title, meta, header, children } = this.props;
    return (
      <SiteConfig>
        {(config) => {
          const { author, description } = config;
          return (
            <>
              <Head title={config.title} />
              <Helmet
                htmlAttributes={{
                  lang,
                }}
                bodyAttributes={{
                  class: classNames('h-layout', className),
                }}
                titleTemplate={`%s | ${title}`}
                meta={[
                  {
                    name: 'description',
                    content: description,
                  },
                  {
                    property: 'og:type',
                    content: 'website',
                  },
                  {
                    property: 'og:title',
                    content: title,
                  },
                  {
                    property: 'og:description',
                    content: description,
                  },
                  {
                    name: 'twitter:card',
                    content: 'summary',
                  },
                  {
                    name: 'twitter:title',
                    content: title,
                  },
                  {
                    name: 'twitter:creator',
                    content: author,
                  },
                  {
                    name: 'twitter:description',
                    content: description,
                  },
                ].concat(meta)}
              />
              <Header {...header} />
              <div className="h-body">{children}</div>
              <Footer />
            </>
          );
        }}
      </SiteConfig>
    );
  }
}

import urlBuilders from './url-builders';

export const mapImage = (image, key = 'image') => ({
  ...(image && {
    [key]: {
      src: urlBuilders.buildFileUrl(image),
      srcSet: urlBuilders.buildFileUrl(image),
    },
  }),
});

export const mapBackgroundImage = (
  backgroundImage,
  key = 'backgroundImage'
) => ({
  ...(backgroundImage && {
    [key]: {
      url: urlBuilders.buildFileUrl(backgroundImage),
    },
  }),
});

export const mapContent = (content) => ({
  ...(content && { content: content.content }),
});

export const mapDescription = (description) => ({
  ...(description && { description: description.description }),
});

export const mapListItems = (items) => ({
  ...(items && {
    items: items.map(({ title, description, image }) => ({
      title,
      ...mapDescription(description),
      ...mapImage(image),
    })),
  }),
});

export const mapHero = ({ heading, content, backgroundImage }) => ({
  heading,
  ...mapContent(content),
  ...mapBackgroundImage(backgroundImage),
});

export const mapForm = ({ buttonText, description, submittedMessage }) => ({
  buttonText,
  ...mapDescription(description),
  ...(submittedMessage && {
    submittedMessage: submittedMessage.submittedMessage,
  }),
});

export const mapSection = ({ heading, content, image, backgroundImage }) => ({
  heading,
  ...mapContent(content),
  ...mapImage(image),
  ...mapBackgroundImage(backgroundImage),
});

export const mapListSection = ({ heading, items, image }) => ({
  heading,
  ...mapListItems(items),
  ...mapImage(image),
});

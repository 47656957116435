import React from 'react';

import AgNav from './ag-nav';
import CreateNav from './create-nav';
import LiquidNav from './liquid-nav';

import targetHelper from '../../utils/target-helper';

const getPageNav = (solutions) => {
  const target = targetHelper.getTarget();

  switch (target) {
    case targetHelper.honeAgKey:
      return <AgNav className="h-header-nav" {...solutions} />;
    case targetHelper.honeCreateKey:
      return <CreateNav className="h-header-nav" />;
    case targetHelper.honeLiquidKey:
      return <LiquidNav className="h-header-nav" />;
  }

  return null;
};

export { getPageNav };

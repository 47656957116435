import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';
import { Button, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import { SiteConfig } from '../config';
import { AppLinks, ExternalLink } from '../links';
import { ContactUsModal } from '../modals';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

import { mapForm } from '../../utils/prop-mapping';

const mapContactUsForm = (contactUsForm) => ({
  form: {
    ...mapForm(contactUsForm),
  },
});

class SocialMediaLink extends React.Component {
  static propTypes = {
    icon: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  };

  render() {
    const { icon, url } = this.props;

    if (!url) {
      return null;
    }

    return (
      <ExternalLink className="h-footer-social-link" url={url}>
        <FontAwesomeIcon icon={icon} />
      </ExternalLink>
    );
  }
}

export default class Footer extends React.Component {
  constructor(...args) {
    super(...args);

    const showModal = () =>
      this.setState({
        show: true,
      });

    const hideModal = () =>
      this.setState({
        show: false,
      });

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            footer: contentfulFooter {
              contactUsForm {
                ...FormFragment
              }
              linkedin
              facebook
              instagram
              twitter
              youTube
            }
          }
        `}
        render={({ footer }) => (
          <SiteConfig>
            {({ abn, copyright }) => (
              <>
                <footer className="h-footer">
                  <Container>
                    <Row className="h-footer-info">
                      <Col xs={12}>
                        <div className="h-footer-social-links">
                          <SocialMediaLink
                            icon={faLinkedin}
                            url={footer.linkedin}
                          />
                          <SocialMediaLink
                            icon={faFacebookSquare}
                            url={footer.facebook}
                          />
                          <SocialMediaLink
                            icon={faInstagramSquare}
                            url={footer.instagram}
                          />
                          <SocialMediaLink
                            icon={faTwitterSquare}
                            url={footer.twitter}
                          />
                          <SocialMediaLink
                            icon={faYoutubeSquare}
                            url={footer.youTube}
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={4}>
                        <AppLinks variant="dark" />
                      </Col>
                      <Col className="h-footer-copyright" xs={12} lg={4}>
                        <div>&copy; {copyright}</div>&nbsp;
                        <div>ABN: {abn}.</div>
                      </Col>
                      <Col xs={12} lg={4}>
                        <Nav className="h-footer-nav">
                          <Nav.Item className="h-footer-nav-item">
                            <Link to="/documents">Terms</Link>
                          </Nav.Item>
                          <Nav.Item className="h-footer-nav-item">
                            <Link to="/documents">Privacy</Link>
                          </Nav.Item>
                          <Nav.Item className="h-footer-nav-item">
                            <Button
                              className="h-footer-button"
                              variant="link"
                              onClick={this.handleShowModal}
                            >
                              Contact Us
                            </Button>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Container>
                </footer>
                <ContactUsModal
                  show={show}
                  onHide={this.handleHideModal}
                  {...mapContactUsForm(footer.contactUsForm)}
                />
              </>
            )}
          </SiteConfig>
        )}
      />
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../images';
import { Form } from '../forms';
import { Markdown } from '../markdown';

import ModalBase from './modal-base';

import { mapImage } from '../../utils/prop-mapping';

export default class FormModal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    submittedMessage: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  static Form = Form;

  constructor(...args) {
    super(...args);

    const reset = () => {
      this.setState({
        submitted: false,
      });
    };

    const submitComplete = () => {
      this.setState({
        submitted: true,
      });
    };

    const submitError = () => {
      this.setState({
        submitted: false,
      });
    };

    this.handleReset = reset.bind(this);
    this.handleSubmitComplete = submitComplete.bind(this);
    this.handleSubmitError = submitError.bind(this);

    this.state = {
      submitted: false,
    };
  }

  render() {
    const { submitted } = this.state;
    const {
      className,
      description,
      submittedMessage,
      onHide,
      ...props
    } = this.props;
    const image = mapImage('form-submitted.png', 'props');

    return (
      <ModalBase
        className={className}
        onHide={() => {
          this.handleReset();
          if (onHide) {
            onHide();
          }
        }}
        {...props}
      >
        {!submitted && description && <Markdown content={description} />}
        <Form
          className="h-modal-form"
          submittedMessage={
            <div className="h-modal-form-message">
              <Image {...image.props} />
              <Markdown content={submittedMessage} />
            </div>
          }
          onSubmitComplete={this.handleSubmitComplete}
          onSubmitError={this.handleSubmitError}
          {...props}
        />
      </ModalBase>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

export default class Image extends React.Component {
  static defaultProps = {
    description: '',
  };

  static propTypes = {
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { src, srcSet, description, className } = this.props;
    return (
      <img className={className} src={src} srcSet={srcSet} alt={description} />
    );
  }
}

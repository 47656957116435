import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';

import { Button, Spinner, Form as FormBase } from 'react-bootstrap';

import FormControl from './form-control';
import {
  FormControlProps,
  FullNameControlProps,
  EmailControlProps,
  CompanyNameControlProps,
  LocationControlProps,
  PhoneNumberControlProps,
  MessageControlProps,
  CheckboxControlProps,
} from './form-control-props';

const formStatus = {
  error: -1,
  none: 0,
  submitting: 1,
  submitted: 2,
};

export default class Form extends React.Component {
  static defaultProps = {
    controls: [],
  };

  static propTypes = {
    className: PropTypes.string,
    controls: PropTypes.arrayOf(PropTypes.instanceOf(FormControlProps))
      .isRequired,
    buttonText: PropTypes.string.isRequired,
    submitTo: PropTypes.string.isRequired,
    submittedMessage: PropTypes.node.isRequired,
    onSubmitComplete: PropTypes.func,
    onSubmitError: PropTypes.func,
  };

  static Status = formStatus;

  static FullName = FullNameControlProps;
  static Email = EmailControlProps;
  static CompanyName = CompanyNameControlProps;
  static Location = LocationControlProps;
  static PhoneNumber = PhoneNumberControlProps;
  static Message = MessageControlProps;
  static Checkbox = CheckboxControlProps;

  constructor(...args) {
    super(...args);

    const change = (id, value) => {
      this.values[id] = value;
    };

    const submit = (ev) => {
      // Prevent the submit from actually happening
      ev.preventDefault();
      ev.stopPropagation();

      // Do the actual form submission
      this.submit();
    };

    this.values = {};
    this.form = React.createRef();
    this.handleChange = change.bind(this);
    this.handleSubmit = submit.bind(this);

    this.state = {
      status: formStatus.none,
      validated: false,
    };
  }

  submit() {
    // Validate the form controls
    const valid = this.form.current.checkValidity();

    this.setState({
      validated: true,
    });

    // If valid submit the form data
    if (valid) {
      const { submitTo, onSubmitComplete, onSubmitError } = this.props;

      this.setState({
        status: formStatus.submitting,
      });

      // Submit the form data
      axios
        .post(submitTo, this.values)
        .then(() => {
          this.setState({
            status: formStatus.submitted,
          });

          onSubmitComplete && onSubmitComplete();
        })
        .catch(() => {
          this.setState({
            status: formStatus.error,
          });

          onSubmitError && onSubmitError();
        });
    }
  }

  render() {
    const { status, validated } = this.state;
    const { className, controls, buttonText, submittedMessage } = this.props;

    // Add any additional class names for managing hide/show
    const classes = [];

    if (status === formStatus.error) {
      classes.push('h-form-error');
    } else if (status === formStatus.submitting) {
      classes.push('h-form-submitting');
    } else if (status === formStatus.submitted) {
      classes.push('h-form-submitted');
    }

    return (
      <div className={classNames('h-form', className, ...classes)}>
        <FormBase
          ref={this.form}
          autoComplete="off"
          validated={validated}
          onSubmit={this.handleSubmit}
          noValidate
        >
          {controls.map((props, index) => (
            <div key={index}>
              <FormControl onChange={this.handleChange} {...props} />
            </div>
          ))}
          <div className="h-form-error-message">
            An error has occurred, please try again.
          </div>
          <Button className="h-form-button" type="submit">
            {buttonText}
          </Button>
        </FormBase>
        <div className="h-form-spinner">
          <Spinner animation="border" />
        </div>
        <div className="h-form-message">{submittedMessage}</div>
      </div>
    );
  }
}

import PropTypes from 'prop-types';

export const imagePropTypes = () =>
  PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
  });

export const backgroundImagePropTypes = () =>
  PropTypes.shape({
    url: PropTypes.string.isRequired,
  });

export const formPropTypes = () =>
  PropTypes.shape({
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    submittedMessage: PropTypes.string.isRequired,
  });

export const childrenOf = (...args) => {
  const types = args.map((type) => type.displayName);
  return (props) => {
    // If there are child nodes validate they are of the expected types
    if (props.children) {
      // Ensure the children value is an array for matching
      const children =
        props.children instanceof Array ? props.children : [props.children];

      // Find the first non-match and throw an error
      const first = children.find((current) => {
        const type = current.type || { displayName: 'INVALID' };
        return types.indexOf(type.displayName) === -1;
      });

      if (first) {
        return new Error(
          `Only [${types.join(', ')}] types are allowed as children.`
        );
      }
    }

    return null;
  };
};

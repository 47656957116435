import React from 'react';

import { SiteConfig } from '../config';

import FormModal from './form-modal';

export default class CarbonModal extends React.Component {
  render() {
    return (
      <SiteConfig>
        {({ contactFormUrl }) => (
          <FormModal
            className="h-carbon-modal"
            title="Find Out More"
            buttonText="Send"
            submittedMessage="Thank you we have now received your details and will be in touch shortly."
            controls={[
              new FormModal.Form.FullName('Name'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.PhoneNumber('PhoneNumber', false),
              new FormModal.Form.Message('Message'),
            ]}
            submitTo={`${contactFormUrl}?subject=Hone Carbon`}
            {...this.props}
          />
        )}
      </SiteConfig>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';

import targetHelper from '../../utils/target-helper';

export default class SiteConfig extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  render() {
    const { children } = this.props;

    // Override the config if hone carbon
    let configOverrides = null;
    let copyrightOverrides = null;

    if (targetHelper.isTarget(targetHelper.honeCarbonKey)) {
      configOverrides = {
        description: 'Smarter soil testing software & hardware',
      };
      copyrightOverrides = {
        copyright: '2021 Hone Carbon Pty. Ltd.',
        abn: '648 397 620',
      };
    } else if (targetHelper.isTarget(targetHelper.honeLiquidKey)) {
      configOverrides = {
        description: 'Smarter liquid testing software & hardware',
      };
    }

    return (
      <StaticQuery
        query={graphql`
          fragment AppStoreFragment on ContentfulAppStore {
            description
            light
            dark
            url
          }
          query SiteConfigQuery {
            site {
              siteMetadata {
                faviconUrl
                logoUrl
                logoSymbolUrl
                testIconUrl
                createIconUrl
                hlrIconUrl
                agPublicUrl
                createPublicUrl
                carbonPublicUrl
                liquidPublicUrl
                loginUrl
                signupUrl
                downloadFormUrl
                eventsFormUrl
                contactFormUrl
                createDemoUrl
                termsUrl
                createTermsUrl
                privacyPolicyUrl
                modelLicenseAgreementUrl
                hlrBrochureUrl
                thirdPartySoftwareUrl
              }
            }
            config: contentfulSiteConfig {
              title
              author
              description
              abn
              copyright
              email
              googleAppStore {
                ...AppStoreFragment
              }
              appleAppStore {
                ...AppStoreFragment
              }
            }
          }
        `}
        render={({ site, config }) =>
          children({
            ...site.siteMetadata,
            ...config,
            ...configOverrides,
            ...copyrightOverrides,
          })
        }
      />
    );
  }
}

export class FormControlProps {
  constructor(id, type, label, required, maxLength, invalidMessage, extras) {
    this.id = id;
    this.type = type;
    this.label = label;
    this.required = required;
    this.maxLength = maxLength;
    this.invalidMessage = invalidMessage;
    this.extras = extras;
    this.valueAccessor = (ref) => ref.current.value;
  }
}

export class FullNameControlProps extends FormControlProps {
  constructor(id, required = true) {
    super(
      id,
      'text',
      'Your Full Name',
      required,
      100,
      'Please enter your full name.'
    );
  }
}

export class EmailControlProps extends FormControlProps {
  constructor(id, required = true) {
    super(id, 'email', 'Your Email', required, 150, 'Please enter your email.');
  }
}

export class CompanyNameControlProps extends FormControlProps {
  constructor(id, required = true) {
    super(
      id,
      'text',
      'Your Company Name',
      required,
      100,
      'Please enter your company name.'
    );
  }
}

export class LocationControlProps extends FormControlProps {
  constructor(id, required = true) {
    super(
      id,
      'text',
      'Your Location',
      required,
      100,
      'Please enter your location.'
    );
  }
}

export class PhoneNumberControlProps extends FormControlProps {
  constructor(id, required = true) {
    super(
      id,
      'text',
      'Your Phone Number',
      required,
      50,
      'Please enter your phone number.'
    );
  }
}

export class MessageControlProps extends FormControlProps {
  constructor(id, label = null, required = true) {
    super(
      id,
      null,
      label || 'Your Message',
      required,
      4000,
      'Please enter your message.',
      { as: 'textarea', rows: 7 }
    );
  }
}

export class CheckboxControlProps extends FormControlProps {
  constructor(id, label) {
    super(id, 'checkbox', label);

    // Override the value accessor to return the checked state
    this.valueAccessor = (ref) => ref.current.checked;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Form } from 'react-bootstrap';

export default class FormControl extends React.Component {
  static defaultProps = {
    required: true,
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    invalidMessage: PropTypes.string,
    extras: PropTypes.object,
    valueAccessor: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    const change = () => {
      const { id, valueAccessor, onChange } = this.props;

      if (valueAccessor && onChange) {
        const value = valueAccessor(this.control);
        onChange(id, value);
      }
    };

    this.control = React.createRef();
    this.handleChange = change.bind(this);
  }

  render() {
    const {
      id,
      type,
      label,
      required,
      maxLength,
      invalidMessage,
      extras,
    } = this.props;

    const className = required ? 'h-form-required' : null;
    const props = type ? { type } : extras;

    // Render either a generic control or a check control (differently)
    let control = (
      <div className="h-form-input">
        <Form.Label className={classNames('h-form-label', className)}>
          {label}
        </Form.Label>
        <Form.Control
          ref={this.control}
          required={required}
          maxLength={maxLength}
          onChange={this.handleChange}
          {...props}
        />
        <Form.Control.Feedback type="invalid">
          {invalidMessage}
        </Form.Control.Feedback>
      </div>
    );

    if (type === 'checkbox') {
      control = (
        <Form.Check
          ref={this.control}
          type="checkbox"
          label={label}
          onClick={this.handleChange}
          custom
        />
      );
    }

    return <Form.Group controlId={id}>{control}</Form.Group>;
  }
}

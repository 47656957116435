import React from 'react';

import { Image } from '../images';

import ModalBase from './modal-base';

import { imagePropTypes } from '../../utils/prop-types';

export default class TestingModal extends React.Component {
  static propTypes = {
    image: imagePropTypes().isRequired,
  };

  render() {
    const { image, ...props } = this.props;
    return (
      <ModalBase className="h-testing-modal" {...props}>
        <Image {...image} />
      </ModalBase>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import marked from 'marked';

export default class Markdown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.string,
  };

  render() {
    const { className, content } = this.props;
    return (
      <div
        className={classNames('h-markdown', className)}
        dangerouslySetInnerHTML={{ __html: marked(content || '') }}
      ></div>
    );
  }
}

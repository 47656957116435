import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Nav as NavBase } from 'react-bootstrap';
import { Link } from 'gatsby';

export default class LiquidNav extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;

    const itemClassName = className ? `${className}-item` : null;

    return (
      <NavBase className={classNames('h-page-nav', className)}>
        <NavBase.Item className={classNames('h-page-nav-item', itemClassName)}>
          <Link to="/#technology" data-smooth-scroll>
            Our Technology
          </Link>
        </NavBase.Item>
        <NavBase.Item className={classNames('h-page-nav-item', itemClassName)}>
          <Link to="/#partnerships" data-smooth-scroll>
            Partnerships
          </Link>
        </NavBase.Item>
        <NavBase.Item className={classNames('h-page-nav-item', itemClassName)}>
          <Link to="/#about" data-smooth-scroll>
            About Us
          </Link>
        </NavBase.Item>
      </NavBase>
    );
  }
}

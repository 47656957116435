const agPublicHost = process.env.GATSBY_AG_PUBLIC_HOST;
const createPublicHost = process.env.GATSBY_CREATE_PUBLIC_HOST;
const carbonPublicHost = process.env.GATSBY_CARBON_PUBLIC_HOST;
const liquidPublicHost = process.env.GATSBY_LIQUID_PUBLIC_HOST;
const apiHost = process.env.GATSBY_API_HOST;
const filesHost = process.env.GATSBY_FILES_HOST;
const legacyHost = process.env.GATSBY_LEGACY_HOST;
const testHost = process.env.GATSBY_TEST_HOST;

const buildUrl = (path, host) => {
  const trimStart = (value) => trim('^[/]+', value);
  const trimEnd = (value) => trim('[/]+$', value);
  const trim = (pattern, value) => {
    const regex = new RegExp(pattern);
    return value.replace(regex, '');
  };

  // Remove the appropriate leading/trailing slashes
  const trimmedHost = host ? host.trim() : '';
  const trimmedPath = path ? path.trim() : '';
  const sanitizedHost = trimEnd(trimmedHost);

  // With the path, check for the relative token
  // If found, prefix with host otherwise return as is
  let sanitizedPath = trimStart(trimmedPath);

  if (sanitizedPath.substr(0, 1) === '~') {
    sanitizedPath = sanitizedPath.substr(1);
    sanitizedPath = trimStart(sanitizedPath);
    sanitizedPath = `${sanitizedHost}/${sanitizedPath}`;
  } else {
    sanitizedPath = path;
  }

  return sanitizedPath;
};

module.exports = {
  buildUrl: (path, host) => buildUrl(path, host),
  buildAgPublicUrl: () => buildUrl('~/', agPublicHost),
  buildCreatePublicUrl: () => buildUrl('~/', createPublicHost),
  buildCarbonPublicUrl: () => buildUrl('~/', carbonPublicHost),
  buildLiquidPublicUrl: () => buildUrl('~/', liquidPublicHost),
  buildApiUrl: (path) => buildUrl(path, apiHost),
  buildFileUrl: (slug) => {
    const cacheKey = process.env.GATSBY_VERSION || Date.now();
    return buildUrl(`~/files/${slug}?c=${cacheKey}`, filesHost);
  },
  buildCreateUrl: () => buildUrl('~/web/create', legacyHost),
  buildTestUrl: () => buildUrl('~/', testHost),
};

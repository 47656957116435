import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { StaticQuery, graphql } from 'gatsby';
import { Button, Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import { SiteConfig } from '../config';
import { Image } from '../images';

import { getPageNav } from '../navs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Cookies from 'universal-cookie';

const mapSolutionDescriptions = ({
  testSolution,
  createSolution,
  honeLabSolution,
}) => ({
  testDescription: testSolution.testSolution,
  createDescription: createSolution.createSolution,
  honeLabDescription: honeLabSolution.honeLabSolution,
});

export default class Header extends React.Component {
  static defaultProps = {
    transparent: false,
  };

  static propTypes = {
    logoSuffix: PropTypes.string,
    transparent: PropTypes.bool,
  };

  cookies = new Cookies();

  constructor(...args) {
    super(...args);

    const toggleNavbar = (expanded) => {
      this.setState({
        expanded,
      });
    };

    this.handleToggleNavbar = toggleNavbar.bind(this);

    this.state = {
      authName: null,
      expanded: false,
    };
  }

  componentDidMount() {
    this.setState({
      authName: this.cookies.get('auth_name'),
    });
  }

  render() {
    const { authName, expanded } = this.state;
    const { logoSuffix, transparent } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            header: contentfulHeader {
              testSolution {
                testSolution
              }
              createSolution {
                createSolution
              }
              honeLabSolution {
                honeLabSolution
              }
            }
          }
        `}
        render={({ header }) => (
          <SiteConfig>
            {({ logoSymbolUrl, loginUrl, signupUrl }) => {
              const solutions = mapSolutionDescriptions(header);
              const pageNav = getPageNav(solutions);

              // Determine the classes needed for the current state
              const classes = [];
              const variant = transparent && !expanded ? 'dark' : 'light';

              if (transparent) {
                classes.push('h-header-transparent');
              }

              if (expanded) {
                classes.push('h-header-expanded');
              }

              if (!pageNav) {
                classes.push('h-header-no-page-nav');
              }

              // Check whether a name claim is available for the auth nav
              let authNav = (
                <>
                  <Nav.Item className="h-header-nav-item">
                    <a href={loginUrl}>Log in</a>
                  </Nav.Item>
                  <Nav.Item className="h-header-nav-item">
                    <Button
                      className="h-header-auth-button"
                      href={signupUrl}
                      size="sm"
                    >
                      Sign Up
                    </Button>
                  </Nav.Item>
                </>
              );

              if (authName) {
                authNav = (
                  <Nav.Item className="h-header-nav-item">
                    <Button
                      className="h-header-auth-button"
                      href={loginUrl}
                      size="sm"
                    >
                      Continue as {authName}
                      <FontAwesomeIcon
                        className="h-header-auth-icon"
                        icon={faArrowRight}
                      />
                    </Button>
                  </Nav.Item>
                );
              }

              // Build the header component
              return (
                <header className={classNames('h-header', ...classes)}>
                  <Navbar
                    className="h-header-navbar"
                    variant={variant}
                    expand="lg"
                    fixed="top"
                    onToggle={this.handleToggleNavbar}
                  >
                    <Container className="h-header-container">
                      <Navbar.Brand className="h-header-logo">
                        <Link to="/#" data-smooth-scroll>
                          <Image src={logoSymbolUrl} description="Hone" />
                          <div className="h-header-logo-text">Hone</div>
                          {logoSuffix && (
                            <div className="h-header-logo-suffix">
                              {logoSuffix}
                            </div>
                          )}
                        </Link>
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="navbar" />
                      <Navbar.Collapse
                        id="navbar"
                        className="h-header-nav-container"
                      >
                        {pageNav}
                        <Nav className="h-header-nav h-header-auth-nav">
                          {authName !== null && authNav}
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </header>
              );
            }}
          </SiteConfig>
        )}
      />
    );
  }
}

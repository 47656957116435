import React from 'react';

import { SiteConfig } from '../config';

import FormModal from './form-modal';

import { formPropTypes } from '../../utils/prop-types';

export default class JoinEventModal extends React.Component {
  static propTypes = {
    form: formPropTypes().isRequired,
  };

  render() {
    return (
      <SiteConfig>
        {({ eventsFormUrl }) => (
          <FormModal
            className="h-join-event-modal"
            controls={[
              new FormModal.Form.FullName('FullName'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.Location('Location'),
              new FormModal.Form.CompanyName('CompanyName', false),
              new FormModal.Form.Checkbox(
                'CanRunAnEvent',
                'I am interested in running an event'
              ),
            ]}
            submitTo={eventsFormUrl}
            {...this.props}
            {...this.props.form}
          />
        )}
      </SiteConfig>
    );
  }
}

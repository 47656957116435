import React from 'react';
import PropTypes from 'prop-types';

export default class Anchor extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    const { id } = this.props;
    return (
      <div className="h-anchor">
        {/* eslint-disable */}
        <a id={id} />
        {/* eslint-enable */}
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Nav as NavBase } from 'react-bootstrap';
import { Link } from 'gatsby';
import { SiteConfig } from '../config';
import { CreateModal } from '../modals';

export default class CreateNav extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  constructor(...args) {
    super(...args);

    const showModal = () =>
      this.setState({
        show: true,
      });

    const hideModal = () =>
      this.setState({
        show: false,
      });

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { className } = this.props;
    const itemClassName = className ? `${className}-item` : null;

    return (
      <SiteConfig>
        {() => (
          <NavBase className={classNames('h-page-nav', className)}>
            <NavBase.Item
              className={classNames('h-page-nav-item', itemClassName)}
            >
              <Link to="/#" data-smooth-scroll>
                Solutions
              </Link>
            </NavBase.Item>
            <NavBase.Item
              className={classNames('h-page-nav-item', itemClassName)}
            >
              <Link to="/#partners" data-smooth-scroll>
                Partners
              </Link>
            </NavBase.Item>
            <NavBase.Item
              className={classNames('h-page-nav-item', itemClassName)}
            >
              <Button variant="link" onClick={this.handleShowModal}>
                Pricing
              </Button>
              <CreateModal
                title="Pricing"
                description="Please get in touch with us to receive our pricing brochure in your inbox."
                show={show}
                onHide={this.handleHideModal}
              />
            </NavBase.Item>
          </NavBase>
        )}
      </SiteConfig>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

export default class ExternalLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, url, children } = this.props;
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
}

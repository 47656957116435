import React from 'react';

import { SiteConfig } from '../config';

import FormModal from './form-modal';

import { formPropTypes } from '../../utils/prop-types';

export default class ContactUsModal extends React.Component {
  static propTypes = {
    form: formPropTypes().isRequired,
  };

  render() {
    return (
      <SiteConfig>
        {({ contactFormUrl }) => (
          <FormModal
            className="h-contact-us-modal"
            title="Contact us"
            controls={[
              new FormModal.Form.FullName('Name'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.PhoneNumber('PhoneNumber', false),
              new FormModal.Form.Message('Message'),
            ]}
            submitTo={`${contactFormUrl}?subject=Contact Us`}
            {...this.props}
            {...this.props.form}
          />
        )}
      </SiteConfig>
    );
  }
}

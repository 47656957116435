import React from 'react';
import PropTypes from 'prop-types';

import { SiteConfig } from '../config';
import { Image } from '../images';

import { mapImage } from '../../utils/prop-mapping';
import { imagePropTypes } from '../../utils/prop-types';

const mapAppStore = (variant, { description, url, ...variants }) => {
  const image = variants[variant];
  return {
    description,
    ...mapImage(image),
    url,
  };
};

class AppLink extends React.Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    image: imagePropTypes().isRequired,
    url: PropTypes.string.isRequired,
  };

  render() {
    const { description, image, url } = this.props;
    return (
      <a href={url}>
        <Image description={description} {...image} />
      </a>
    );
  }
}

export default class AppLinks extends React.Component {
  static defaultProps = {
    variant: 'light',
  };

  static propTypes = {
    variant: PropTypes.string,
  };

  render() {
    return (
      <SiteConfig>
        {({ googleAppStore, appleAppStore }) => {
          const { variant } = this.props;
          return (
            <div className="h-app-links">
              <AppLink {...mapAppStore(variant, googleAppStore)} />
              <AppLink {...mapAppStore(variant, appleAppStore)} />
            </div>
          );
        }}
      </SiteConfig>
    );
  }
}

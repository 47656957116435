import React from 'react';
import PropTypes from 'prop-types';

import { SiteConfig } from '../config';
import FormModal from './form-modal';

export default class CreateModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  render() {
    const { title, ...props } = this.props;
    return (
      <SiteConfig>
        {({ contactFormUrl }) => (
          <FormModal
            className="h-create-modal"
            title={title}
            buttonText="Send"
            submittedMessage="Thank you we have now received your details and will be in touch shortly."
            controls={[
              new FormModal.Form.FullName('Name'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.PhoneNumber('PhoneNumber', false),
              new FormModal.Form.Message('Message'),
            ]}
            submitTo={`${contactFormUrl}?subject=${title}`}
            {...props}
          />
        )}
      </SiteConfig>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Modal } from 'react-bootstrap';

export default class ModalBase extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, title, show, onHide, children } = this.props;
    return (
      <Modal
        className={classNames('h-modal', className)}
        size="lg"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  }
}
